import React from "react";
import suspendImage from "../images/suspend.png";
import Image from "next/image";
import Link from "next/link";
import {layoutTranslations} from "../services/translationKeys";
import {layoutProps} from "../services/layoutProps";
import {NextPage} from "next";
import {domainUrl} from "../helper"

const Suspend: NextPage<any> = ({dbName}) => (
    <div className="SuspendMain">
        <div className="SuspendBody container p-0">
            <div className="Suspend">
                <div>
                    <div className="SuspendImage">
                        <Image
                            width={480}
                            height={429}
                            src={domainUrl(`${dbName}/${suspendImage?.src}`)}
                            alt="Suspend Image"
                        />
                    </div>
                </div>
                <h1>
                    {dbName} <br/>
                    is no longer available
                </h1>
                <h3>
                    This website has been archived or suspended in accordance with our{" "}
                    <Link
                        href="https://www.zegashop.com/web/terms-of-service/"
                        prefetch={false}
                    >
                        <a>Terms Of Service.</a>
                    </Link>
                </h3>
                <h5>
                    For more information, Please,{" "}
                    <Link
                        href="https://www.zegashop.com/web/contact-us/"
                        prefetch={false}
                    >
                        <a target="_blank">Contact us.</a>
                    </Link>
                </h5>
            </div>
        </div>
    </div>
);

export default Suspend

export async function getServerSideProps({locale, req}: any) {
    const typedReq = req as unknown as {
        m: { [key: string]: any }[],
        defaultDB: { db_name: string },
        assets: { logo_image: string },
    }
    const wrapperProps = await layoutProps({
        requestHeaders: req.headers,
        requestDbName: typedReq.defaultDB.db_name,
        requestModels: typedReq.m,
        requestLogo: typedReq.assets.logo_image,
        nextJSLocale: locale,
        translationsObject: layoutTranslations
    })
    const { suspendStatus, selectedLocale } = wrapperProps
    if (String(suspendStatus) !== "469") {
        return {
            redirect: { destination: '/', permanent: false },
        }
    }
    return {
        props: { locale: selectedLocale, ...wrapperProps },
    };
}
